import {Component,OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroup} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ConcesionariosService } from 'src/app/services/concesionarios.service';
import { ContactoService } from 'src/app/services/contacto.service';


@Component({
  selector: 'app-citas-cambio-bms',
  templateUrl: './citas-cambio-bms.component.html',
  styleUrls: ['./citas-cambio-bms.component.css']
})
export class CitasCambioBmsComponent implements OnInit {
  public exito: boolean = false;
  public citaForm: FormGroup;

  validando=false
  validacion_ok = true
  enviando=false
  isLoading = true
  isLinear = true;

  cambiarDireccion = false

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  threeFormGroup: FormGroup;
  opciones:any
  opciones2:any
  opciones3:any
  enviado_ok = false

  ciudades:any

  public concesionarios: any [] = [];
  public provincias: any [] = [];
  @ViewChild('stepper') stepper: MatStepper;

  constructor(private _formBuilder: FormBuilder,private contactoService: ContactoService, private concesionariosService: ConcesionariosService, private router: Router) { }

  ngOnInit(): void {
    this.createForm();
  }

  buscarVinCode(){
    if (this.firstFormGroup.invalid) {
      return
    }
    this.validando = true

    this.concesionariosService.validarVinParaCita(this.firstFormGroup.controls.matricula.value ).subscribe({
      next: (r: any) => {
       if (r.message == 0) {
        alert('No existe ese numero de matricula.')
        this.firstFormGroup.controls.matricula.setValue('')
        this.validando = false
        return
       }else{
        this.f.concesionario.setValue(r.message.customer)
        this.f.vin_code.setValue(r.message.vin_number)
        this.concesionariosService.getPlacesBMS({'concesionario':r.message.customer}).subscribe({
          next: (r: any) => {
            this.concesionarios = r.message 
          }
        })
        this.stepper.next();
        this.validando = false
       }
       this.validando = false
      }
    }) 
  }

  createForm() {
    this.firstFormGroup = this._formBuilder.group({
      matricula: ['', Validators.required]
    })
    this.secondFormGroup = this._formBuilder.group({
      vin_code: ['', Validators.required],
      concesionario: [''],
      pais:['ESPAÑA'],
      ciudad: [''],
      lugar_de_la_cita: ['', Validators.required]
    })
    this.threeFormGroup = this._formBuilder.group({
      semana: ['', Validators.required],
      semana2: ['', Validators.required],
      semana3: ['', Validators.required]
    })
  }

  get f() {
    return this.secondFormGroup.controls;
  }

  semanasDisponibles(){
    this.opciones = []
    this.concesionariosService.getAvailableDaysBMS({'direccion':this.f.lugar_de_la_cita.value}).subscribe({
      next: (r: any) => {
       this.opciones = r.message
      }
    })
  }

  enviarCita(){
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || this.threeFormGroup.invalid) {
      return
    }

    this.enviando = true

    let fecha_desde1 = this.opciones[this.threeFormGroup.controls.semana.value].desde
    let fecha_hasta1 = this.opciones[this.threeFormGroup.controls.semana.value].hasta

    let fecha_desde2 = this.opciones2[this.threeFormGroup.controls.semana2.value].desde
    let fecha_hasta2 = this.opciones2[this.threeFormGroup.controls.semana2.value].hasta

    let fecha_desde3 = this.opciones3[this.threeFormGroup.controls.semana3.value].desde
    let fecha_hasta3 = this.opciones3[this.threeFormGroup.controls.semana3.value].hasta

    let fechas = [
      {'desde':fecha_desde1,'hasta':fecha_hasta1},
      {'desde':fecha_desde2,'hasta':fecha_hasta2},
      {'desde':fecha_desde3,'hasta':fecha_hasta3}
    ]
    
    let newCita = Object.assign( this.firstFormGroup.value ,this.secondFormGroup.value , {'fechas':fechas})

    console.log(newCita)
    this.concesionariosService.altaCitaBMS(newCita).subscribe({
      next: (r: any) => {
       this.enviado_ok = true
      }
    })
  }

  cambiarDireccionFuncion(){
    this.cambiarDireccion = true
    this.f.lugar_de_la_cita.setValue('')
    this.concesionariosService.getCiudadesPorPais(this.f.pais.value).subscribe({
      next: (r: any) => {
        this.ciudades = r.message 
      }
    })
  }

  filtrarPlaces(){
    this.concesionariosService.getPlacesBMS({'ciudad':this.f.ciudad.value}).subscribe({
      next: (r: any) => {
        this.concesionarios = r.message
        if (this.concesionarios.length == 1) {
          this.f.lugar_de_la_cita.setValue(this.concesionarios[0].name)
          this.semanasDisponibles()
        }
      }
    })
  }

  filtrarSegundaOpcion(){
    this.opciones2 = []
    this.opciones3 = []
    let datos = {
      'direccion': this.f.lugar_de_la_cita.value,
      'fecha': this.opciones[this.threeFormGroup.controls.semana.value].desde
    }
    this.concesionariosService.getAvailableDaysBMS(datos).subscribe({
      next: (r: any) => {
       this.opciones2 = r.message
      }
    })
  }
  filtrarTerceraOpcion(){
    this.opciones3 = []
    let datos = {
      'direccion': this.f.lugar_de_la_cita.value,
      'fecha': this.opciones2[this.threeFormGroup.controls.semana2.value].desde
    }
    this.concesionariosService.getAvailableDaysBMS(datos).subscribe({
      next: (r: any) => {
       this.opciones3 = r.message
      }
    })
  }
}
